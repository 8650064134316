export default [
  // {
  //   header: 'Apps & Pages',
  // },
  // {
  //   title: 'Anasayfa',
  //   route: 'dashboard',
  //   icon: 'HomeIcon',
  //   action: 'read',
  //   resource: 'login_user',
  // },
  {
    title: 'Merkezler',
    route: 'customer-plants',
    icon: 'MapPinIcon',
    action: 'read',
    resource: 'client',
  },
  {
    title: 'Cihazlar',
    route: 'customer-devices',
    icon: 'CastIcon',
    action: 'read',
    resource: 'client',
  },
  {
    title: 'Raporlar',
    route: 'apps-calendar',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'client',
  },
  {
    title: 'Alarm',
    route: 'customer-alarms',
    icon: 'AlertTriangleIcon',
    action: 'read',
    resource: 'client',
  },
  // {
  //   title: 'Sistem Logları',
  //   route: 'apps-calendar',
  //   icon: 'CrosshairIcon',
  //   action: 'read',
  //   resource: 'client',
  // },
  // {
  //   title: 'Bildirim',
  //   route: 'apps-calendar',
  //   icon: 'RadioIcon',
  //   action: 'read',
  //   resource: 'client',
  // },
  {
    title: 'Müşteriler',
    route: 'customers-list',
    icon: 'UsersIcon',
    resource: 'admin',
  },
  {
    title: 'Genel Tanımlar',
    icon: 'SettingsIcon',
    resource: 'admin',
    children: [
      {
        title: 'Cihaz Tipi',
        route: 'device_types',
        resource: 'admin',
      },
      {
        title: 'Para Birimleri',
        route: 'currencies',
        resource: 'admin',
      },
    ],
  },
  // {
  //   title: 'Lisans Yönetimi',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  //   action: 'read',
  //   resource: 'admin',
  // },
  // {
  //   title: 'Çıkış',
  //   route: 'auth-logout',
  //   icon: 'LogOutIcon',
  //   action: 'read',
  //   resource: 'login_user',
  // },
]
