<template>
  <div class="row justify-content-center">
    <p class="clearfix mb-0 ">
    <span class=" d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
          class="ml-25"
          href="https://gtrsoft.com"
          target="_blank"
      >GTRSOFT</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    </p>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
